import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// Register plugins
gsap.registerPlugin(ScrollTrigger);

// Expose GSAP and its plugins to the global scope
window.ScrollTrigger = ScrollTrigger;
window.gsap = gsap;

window.dispatchEvent(new CustomEvent('gsap-ready'));